import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Watchpage from './pages/watchpage';
import Homepage from './pages/homepage';

function App() {
  return (
    <div className="App bg-background-primary w-full h-full text-2xl">
      <BrowserRouter>
        <Routes>
          <Route path="/homesss" element={<Homepage />} />
          <Route path="/watch" element={<Watchpage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
